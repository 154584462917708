<template>
  <div class="content" id="pjax-container">
    <div class="row">
      <div class="col-md-6">
        <div class="block block-rounded h-100 mb-0">
          <div class="block-header block-header-default">
            <h3 class="block-title">账号密码添加（请使用宥马账号登录）</h3>
          </div>
          <div class="block-content">
            <form onsubmit="return false;" id="login-form">
              <div class="mb-4">
                <label class="form-label" for="login-username">手机号</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="far fa-user"></i>
                  </span>
                  <input
                    v-model="account.uin"
                    type="text"
                    class="form-control"
                    id="login-username"
                    name="login-username"
                    placeholder="请输入手机号"
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="form-label" for="login-password">密码</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="far fa-surprise"></i>
                  </span>
                  <input
                    v-model="account.pwd"
                    type="password"
                    class="form-control"
                    id="login-password"
                    name="login-password"
                    placeholder="请输入密码"
                  />
                </div>
              </div>

              <div class="mb-4">
                <button
                  type="submit"
                  class="btn btn-alt-primary"
                  @click="add()"
                >
                  <i class="fa fa-arrow-right opacity-50 me-1"></i> 登录
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-1">
        <div
          class="alert alert-info d-flex align-items-center justify-content-between"
          role="alert"
        >
          <p class="mb-0">请使用宥马运动绑定的手机号进行登录</p>
          <i class="fa fa-fw fa-info ms-2"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addYmUser } from "@/api/youma";

export default {
  name: "Add",
  data() {
    return {
      account: {},
    };
  },
  methods: {
    add() {
      addYmUser(this.account)
        .then((message) => {
          this.$message.success(message);
          setTimeout(() => {
            this.$router.push("/youma/list");
          }, 1000);
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style scoped></style>
